import { accordion } from './components/accordion'
import { swiperDesktop, swiperMobile } from './components/swiper'
import { hamburger, mobileHeader, stickyHeader } from './components/header'
import { viewAnimation, smoothScroll } from './util/animation'
import { outputMonth, countDown } from './util/dates'
import { inlineVideo } from './util/video'
import { phoneConcat, readMore } from './util/text'
import { cf7Redirect } from './util/window'
import collapsible from './components/collapsible'
import modal, { exitIntent } from './components/modal'
import {
	multistep,
	setGlobalValues,
	readGlobalValues,
} from './components/multi-step-form.js'
;(function ($) {
	$(window).on('load', function () {
		collapsible()
		modal()
		exitIntent()
		mobileHeader()
		stickyHeader()
		hamburger()
		outputMonth()
		viewAnimation()
		accordion()
		swiperDesktop()
		swiperMobile()
		smoothScroll()
		inlineVideo()
		phoneConcat()
		multistep()
		setGlobalValues()
		readGlobalValues()
	})
})(jQuery)
