import { Modal } from 'bootstrap'

export default function modal() {
	let modalLinks = [
		{
			selector: '[data-bs-target*="#optin-popup"]',
			modal: 'optin-popup',
		},
		{
			selector: '[data-bs-target*="#webinar-popup"]',
			modal: 'webinar-popup',
		},
		{
			selector: '[data-bs-target*="#exit-popup"]',
			modal: 'exit-popup',
		},
		{
			selector: '[data-bs-target*="#terms-and-conditions-popup"]',
			modal: 'terms-and-conditions-popup',
		},
	]

	modalLinks.forEach((link) => {
		let links = document.querySelectorAll(link.selector)

		if (links.length) {
			for (var i = 0; i < links.length; i++) {
				links[i].addEventListener('click', (evt) => {
					evt.preventDefault()
					let target = document.querySelector('#' + link.modal)
					new Modal(target).show()
				})
			}
		}
	})

	let modals = document.querySelectorAll('.modal')

	if (modals.length) {
		for (var i = 0; i < modals.length; i++) {
			new Modal(modals[i])
		}
	}
}

export function exitIntent() {
	const exitIntentTrigger = document.querySelector('#exit-intent-trigger')
	let isTriggered = 0

	if (exitIntentTrigger) {
		return setTimeout(function () {
			document.addEventListener('mouseout', function (e) {
				if (
					((e.toElement === null && e.relatedTarget === null) ||
						(typeof e.toElement == 'undefined' &&
							e.relatedTarget === null)) &&
					isTriggered < 1
				) {
					exitIntentTrigger.click()
					isTriggered = 1
				}
			})
		}, 4000)
	}
}
